import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/profilePage";
import {Redirect} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import {DataGrid} from "@mui/x-data-grid";
import Parallax from "../../components/Parallax/Parallax";
import config from "../../config/config.json"
import Tooltip from "@material-ui/core/Tooltip";
import NavPills from "../../components/NavPills/NavPills";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const useStyles = makeStyles(styles);
export default function MehlbotSalesHistoryPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const sessionToken = localStorage.getItem("session_token")
    if (!sessionToken) {
        return (<Redirect to="/login"/>)
    }

    //hover
    function renderCellFunc(params) {
        let path = "https://d2r-member.com/image/" + params.row.image_path
        return <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
            <div>{params.row.name}</div>
        </Tooltip>;
    }
    renderCellFunc.displayName = "Cell Name"

    function renderCellFunc2(params) {
        let path = "https://d2r-member.com/image/" + params.row.image_path
        console.log(path)
        return <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
            <div>{params.row.stats_description}</div>
        </Tooltip>;
    }
    renderCellFunc2.displayName = "Cell Description"

    const [permSalesHistoryDataFG, setPermSalesHistoryDataFG] = useState([]);
    const [permSalesHistoryDataFGSoldByMe, setPermSalesHistoryDataFGSoldByMe] = useState([]);
    const [permSalesHistoryDataFGOwnedByMe, setPermSalesHistoryDataFGOwnedByMe] = useState([]);
    const [permSalesHistoryDataFGNodeByMe, setPermSalesHistoryDataFGNodeByMe] = useState([]);
    const [permSalesHistoryDataFiat, setPermSalesHistoryDataFiat] = useState([]);
    const [permSalesHistoryDataFiatSoldByMe, setPermSalesHistoryDataFiatSoldByMe] = useState([]);
    const [permSalesHistoryDataFiatOwnedByMe, setPermSalesHistoryDataFiatOwnedByMe] = useState([]);
    const [permSalesHistoryDataFiatNodeByMe, setPermSalesHistoryDataFiatNodeByMe] = useState([]);

    const [loadingData, setLoadingData] = useState(true);

    async function getPermSalesHistory() {
        const fetchingNotification = toast.loading("Fetching data, please wait...");
        setPermSalesHistoryDataFG([]);
        setPermSalesHistoryDataFGSoldByMe([]);
        setPermSalesHistoryDataFGOwnedByMe([]);
        setPermSalesHistoryDataFGNodeByMe([]);
        setPermSalesHistoryDataFiat([]);
        setPermSalesHistoryDataFiatSoldByMe([]);
        setPermSalesHistoryDataFiatOwnedByMe([]);
        setPermSalesHistoryDataFiatNodeByMe([]);

        let url = config.backend_base_url + 'perm/sales/' + season;
        try {
            const response = await axios.get(url, { headers: { 'session-token': sessionToken } });

            if ((response.data != null) && (response.data.items.constructor === Array)) {
                const requestBy = response.data.request_by.toLowerCase();
                const fgData = response.data.items.filter(item => item.currency_sold === "fg");
                setPermSalesHistoryDataFG(fgData);
                setPermSalesHistoryDataFGSoldByMe(fgData.filter(item => requestBy === item.reseller_name.toLowerCase()));
                setPermSalesHistoryDataFGOwnedByMe(fgData.filter(item => requestBy === item.shareholder_name.toLowerCase()));
                setPermSalesHistoryDataFGNodeByMe(fgData.filter(item => requestBy === item.node_host_name.toLowerCase()));

                const fiatData = response.data.items.filter(item => item.currency_sold !== "fg");
                setPermSalesHistoryDataFiat(fiatData);
                setPermSalesHistoryDataFiatSoldByMe(fiatData.filter(item => requestBy === item.reseller_name.toLowerCase()));
                setPermSalesHistoryDataFiatOwnedByMe(fiatData.filter(item => requestBy === item.shareholder_name.toLowerCase()));
                setPermSalesHistoryDataFiatNodeByMe(fiatData.filter(item => requestBy === item.node_host_name.toLowerCase()));
            } else {
                setPermSalesHistoryDataFG([]);
                setPermSalesHistoryDataFiat([]);
            }
        } catch (error) {
            console.log("Error ========>", error);
            toast.error("No Data found");
        }
        toast.dismiss(fetchingNotification);
    }

    async function getAvailableSeasons() {
        const loading = toast.loading("Fetching available seasons, please wait...");
        let url = config.backend_base_url + 'seasons';
        try {
            const response = await axios.get(url, { headers: { 'session-token': sessionToken } });
            toast.dismiss(loading)
            if ((response.data != null) && (response.data.constructor === Array)) {
                setAvailableSeasons(response.data)
                setSeason(response.data[response.data.length-1])
            } else {
                setAvailableSeasons([]);
            }
        } catch (error) {
            toast.dismiss(loading)
            console.log("Error ========>", error);
            toast.error("No available Seasons found");
        }
    }

    const permHistoryColumnsFiat = [
        {
            field: "name", headerName: "Item Name", flex: 5, renderCell: renderCellFunc,
        },
        {
            field: "ocr_string", headerName: "Full Item", flex: 20,
        },
        {
            field: 'involved',
            headerName: 'seller,owner,host',
            renderCell: (params) => {
                const { node_host_name, reseller_name, shareholder_name } = params.row;
                console.log("params.row:", params.row);
                const host = node_host_name || 'Unknown Host';
                const seller = reseller_name || 'Unknown Seller';
                const owner = shareholder_name || 'Unknown Owner';
                return `${seller}, ${owner}, ${host}`;
            },
        },
        {
            field: 'fiat_price',
            headerName: 'price',
            flex: 1,
            type: 'number',
            valueGetter: (params) => parseFloat(params.value, 10),
            valueParser: (value) => parseFloat(value, 10),
        },
        {
            field: "sold_at",
            headerName: "Sold at",
            width: 150,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    //second: 'numeric', // This line is omitted to exclude seconds
                });
            },
        },
    ];

    const permHistoryColumnsFG = [
        {
            field: "name", headerName: "Item Name", flex: 5, renderCell: renderCellFunc,
        },
        {
            field: "ocr_string", headerName: "Full Item", flex: 20,
        },
        {
            field: 'involved',
            headerName: 'seller,owner,host',
            renderCell: (params) => {
                const { node_host_name, reseller_name, shareholder_name } = params.row;
                const host = node_host_name || 'Unknown Host';
                const seller = reseller_name || 'Unknown Seller';
                const owner = shareholder_name || 'Unknown Owner';
                return `${seller}, ${owner}, ${host}`;
            },
        },
        {
            field: 'fg_price',
            headerName: 'price',
            flex: 1,
            type: 'number',
            valueGetter: (params) => parseFloat(params.value, 10),
            valueParser: (value) => parseFloat(value, 10),
        },
        {
            field: "sold_at",
            headerName: "Sold at",
            width: 150,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });
            },
        },
    ];

    async function getProfile() {
        await axios.get(config.backend_base_url + 'profile', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                //what to do
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    const [availableSeasons, setAvailableSeasons] = useState([0]);
    const [season, setSeason] = useState(0);
    const [oldSeason, setOldSeason] = useState(season);
    React.useEffect(() => {
            if (oldSeason !== season) {
                setOldSeason(season)
                getPermSalesHistory()
            }
        }
    )

    if (loadingData) {
        const loading = toast.loading("loading available seasons")
        getAvailableSeasons()
        getProfile()
        setLoadingData(false)
        toast.dismiss(loading)
    }

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />
            <Parallax small filter headerHeight={headerHeight}
            />

            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} style={{width: "80vw", position: "relative", maxWidth: "80vw", left: "60%", marginLeft: "-50vw", textAlign: "center"}}>
                    <NavPills
                        queryKey={"t"}
                     alignCenter
                     fullWidth
                     onChange={function () {
                     }}
                     color="primary"
                     tabs={[
                         {
                             tabName: "Sales History FG",
                             tabButton: "Sales History FG (" + permSalesHistoryDataFG.length+")",
                             tabContent: (
                                 <div>
                                     <Select
                                         id="rerouteTradeNewRealm"
                                         fullWidth={true}
                                         value={season}
                                         onChange={(params) => {
                                             setSeason(params.target.value)
                                         }}
                                     >
                                         {availableSeasons.map((season, key) =>
                                             <MenuItem key={key} selected={key+1 === availableSeasons.length}
                                                       value={season}>Season {season}</MenuItem>
                                         )}
                                     </Select>
                                     <NavPills
                                         queryKey={"f"}
                                         alignCenter
                                         fullWidth
                                         onChange={function () {
                                         }}
                                         color="primary"
                                         tabs={[
                                             {
                                                 tabName: "all",
                                                 tabButton: "All (" + permSalesHistoryDataFG.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFG}
                                                                     rows={permSalesHistoryDataFG}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                             {
                                                 tabName: "soldbyme",
                                                 tabButton: "Sold By Me (" + permSalesHistoryDataFGSoldByMe.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFG}
                                                                     rows={permSalesHistoryDataFGSoldByMe}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                             {
                                                 tabName: "ownedbyme",
                                                 tabButton: "Owned By Me (" + permSalesHistoryDataFGOwnedByMe.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFG}
                                                                     rows={permSalesHistoryDataFGOwnedByMe}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[100]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                             {
                                                 tabName: "nodebyme",
                                                 tabButton: "Node By Me (" + permSalesHistoryDataFGNodeByMe.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFG}
                                                                     rows={permSalesHistoryDataFGNodeByMe}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[100]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                         ]}
                                     />
                                 </div>
                             ),
                         },
                         {
                             tabName: "Sales History Fiat",
                             tabButton: "Sales History Fiat (" + permSalesHistoryDataFiat.length+")",
                             tabContent: (
                                 <div>
                                     <Select
                                         id="rerouteTradeNewRealm"
                                         fullWidth={true}
                                         value={season}
                                         onChange={(params) => {
                                             setSeason(params.target.value)
                                         }}
                                     >
                                         {availableSeasons.map((season, key) =>
                                             <MenuItem key={key} selected={key+1 === availableSeasons.length}
                                                       value={season}>Season {season}</MenuItem>
                                         )}
                                     </Select>
                                     <NavPills
                                         queryKey={"f"}
                                         alignCenter
                                         fullWidth
                                         onChange={function () {
                                         }}
                                         color="primary"
                                         tabs={[
                                             {
                                                 tabName: "all",
                                                 tabButton: "All (" + permSalesHistoryDataFiat.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFiat}
                                                                     rows={permSalesHistoryDataFiat}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                             {
                                                 tabName: "soldbyme",
                                                 tabButton: "Sold By Me (" + permSalesHistoryDataFiatSoldByMe.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFiat}
                                                                     rows={permSalesHistoryDataFiatSoldByMe}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                             {
                                                 tabName: "ownedbyme",
                                                 tabButton: "Owned By Me (" + permSalesHistoryDataFiatOwnedByMe.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFiat}
                                                                     rows={permSalesHistoryDataFiatOwnedByMe}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[100]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                             {
                                                 tabName: "nodebyme",
                                                 tabButton: "Node By Me (" + permSalesHistoryDataFiatNodeByMe.length+")",
                                                 tabContent: (
                                                     <GridContainer spacing={1}>
                                                         <GridItem xs={12} sm={12} md={12} xl={12}>
                                                             <div style={{height: "100%", width: "100%"}}>
                                                                 <DataGrid
                                                                     autoHeight
                                                                     getRowWidth={() => 'auto'}
                                                                     getRowHeight={() => 'auto'}
                                                                     columns={permHistoryColumnsFiat}
                                                                     rows={permSalesHistoryDataFiatNodeByMe}
                                                                     pageSize={100}
                                                                     rowsPerPageOptions={[100]}
                                                                 />
                                                             </div>
                                                         </GridItem>
                                                     </GridContainer>
                                                 ),
                                             },
                                         ]}
                                     />
                                 </div>
                             ),
                         },
                     ]}
                    />
                </div>
                <Footer/>
            </div>
        </div>
    );
}