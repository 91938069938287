import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/analyticsPage";
import {Redirect} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Parallax from "../../components/Parallax/Parallax";
import config from "../../config/config.json"
import Tooltip from "@material-ui/core/Tooltip";
import NavPills from "../../components/NavPills/NavPills";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SupplierAnalytics } from "./components/SupplierAnalytics";
import { SellerAnalytics } from "./components/SellerAnalytics";
import {NodeAnalytics} from "./components/NodeAnalytics";

const useStyles = makeStyles(styles);
export default function MehlbotSalesHistoryPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const sessionToken = localStorage.getItem("session_token")
    if (!sessionToken) {
        return (<Redirect to="/login"/>)
    }

    //hover
    function renderCellFunc(params) {
        let path = "https://d2r-member.com/image/" + params.row.image_path
        return <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
            <div>{params.row.name}</div>
        </Tooltip>;
    }
    renderCellFunc.displayName = "Cell Name"

    function renderCellFunc2(params) {
        let path = "https://d2r-member.com/image/" + params.row.image_path
        return <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
            <div>{params.row.stats_description}</div>
        </Tooltip>;
    }
    renderCellFunc2.displayName = "Cell Description"

    const [permSalesHistoryDataFGOwnedByMe, setPermSalesHistoryDataFGOwnedByMe] = useState([]);
    const [permSalesHistoryDataFGSoldByMe, setPermSalesHistoryDataFGSoldByMe] = useState([]);
    const [permSalesHistoryDataFGNodeByMe, setPermSalesHistoryDataFGNodeByMe] = useState([]);
    const [permSalesHistoryDataFiatSoldByMe, setPermSalesHistoryDataFiatSoldByMe] = useState([]);
    const [permSalesHistoryDataFiatOwnedByMe, setPermSalesHistoryDataFiatOwnedByMe] = useState([]);
    const [permSalesHistoryDataFiatNodeByMe, setPermSalesHistoryDataFiatNodeByMe] = useState([]);

    const [loadingData, setLoadingData] = useState(true);

    async function getPermSalesHistory() {
        const fetchingNotification = toast.loading("Fetching data, please wait...");

        let url = config.backend_base_url + 'perm/sales/' + season;
        try {
            const response = await axios.get(url, { headers: { 'session-token': sessionToken } });

            if ((response.data != null) && (response.data.items.constructor === Array)) {
                const requestBy = response.data.request_by.toLowerCase();
                const fgData = response.data.items.filter(item => item.currency_sold === "fg");
                setPermSalesHistoryDataFGOwnedByMe(fgData.filter(item => requestBy === item.shareholder_name.toLowerCase()));
                setPermSalesHistoryDataFGSoldByMe(fgData.filter(item => requestBy === item.reseller_name.toLowerCase()));
                setPermSalesHistoryDataFGNodeByMe(fgData.filter(item => requestBy === item.node_host_name.toLowerCase()));

                const fiatData = response.data.items.filter(item => item.currency_sold !== "fg");
                setPermSalesHistoryDataFiatOwnedByMe(fiatData.filter(item => requestBy === item.shareholder_name.toLowerCase()));
                setPermSalesHistoryDataFiatSoldByMe(fiatData.filter(item => requestBy === item.reseller_name.toLowerCase()));
                setPermSalesHistoryDataFiatNodeByMe(fiatData.filter(item => requestBy === item.node_host_name.toLowerCase()));
            }
        } catch (error) {
            console.log("Error ========>", error);
            toast.error("No Data found");
        }
        toast.dismiss(fetchingNotification);
    }

    async function getAvailableSeasons() {
        const loading = toast.loading("Fetching available seasons, please wait...");
        let url = config.backend_base_url + 'seasons';
        try {
            const response = await axios.get(url, { headers: { 'session-token': sessionToken } });
            toast.dismiss(loading)
            if ((response.data != null) && (response.data.constructor === Array)) {
                setAvailableSeasons(response.data)
                setSeason(response.data[response.data.length-1])
            } else {
                setAvailableSeasons([]);
            }
        } catch (error) {
            toast.dismiss(loading)
            console.log("Error ========>", error);
            toast.error("No available Seasons found");
        }
    }

    async function getProfile() {
        await axios.get(config.backend_base_url + 'profile', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                //what to do
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    const [availableSeasons, setAvailableSeasons] = useState([0]);
    const [season, setSeason] = useState(0);
    const [oldSeason, setOldSeason] = useState(season);
    React.useEffect(() => {
            if (oldSeason !== season) {
                setOldSeason(season)
                getPermSalesHistory()
            }
        }
    )

    if (loadingData) {
        const loading = toast.loading("loading available seasons")
        getAvailableSeasons()
        getProfile()
        setLoadingData(false)
        toast.dismiss(loading)
    }

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />
            <Parallax small filter headerHeight={headerHeight}
            />

            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} style={{width: "80vw", position: "relative", maxWidth: "80vw", left: "60%", marginLeft: "-50vw", textAlign: "center"}}>
                    <NavPills
                        queryKey={"t"}
                     alignCenter
                     fullWidth
                     onChange={function () {
                     }}
                     color="primary"
                     tabs={[
                         {
                             tabName: "My Sales Analytics",
                             tabButton: "My Sales Analytics",
                             tabContent: (
                                 <div>
                                     <Select
                                         id="rerouteTradeNewRealm"
                                         fullWidth={true}
                                         value={season}
                                         onChange={(params) => {
                                             setSeason(params.target.value)
                                         }}
                                     >
                                         {availableSeasons.map((season, key) =>
                                             <MenuItem key={key} selected={key+1 === availableSeasons.length}
                                                       value={season}>Season {season}</MenuItem>
                                         )}
                                     </Select>
                                     <SellerAnalytics
                                         analyticsData={[...permSalesHistoryDataFGSoldByMe, ...permSalesHistoryDataFiatSoldByMe]}
                                         classes={classes}
                                     />
                                 </div>
                             ),
                         },
                         {
                             tabName: "My Supply Analytics",
                             tabButton: "My Supply Analytics",
                             tabContent: (
                                 <div>
                                     <Select
                                         id="rerouteTradeNewRealm"
                                         fullWidth={true}
                                         value={season}
                                         onChange={(params) => {
                                             setSeason(params.target.value)
                                         }}
                                     >
                                         {availableSeasons.map((season, key) =>
                                             <MenuItem key={key} selected={key+1 === availableSeasons.length}
                                                       value={season}>Season {season}</MenuItem>
                                         )}
                                     </Select>
                                     <SupplierAnalytics 
                                         analyticsData={[...permSalesHistoryDataFGOwnedByMe, ...permSalesHistoryDataFiatOwnedByMe]}
                                         classes={classes}
                                         isLoading={loadingData}
                                     />
                                 </div>
                             ),
                         },
                         {
                             tabName: "My Node Analytics",
                             tabButton: "My Node Analytics",
                             tabContent: (
                                 <div>
                                     <Select
                                         id="rerouteTradeNewRealm"
                                         fullWidth={true}
                                         value={season}
                                         onChange={(params) => {
                                             setSeason(params.target.value)
                                         }}
                                     >
                                         {availableSeasons.map((season, key) =>
                                             <MenuItem key={key} selected={key+1 === availableSeasons.length}
                                                       value={season}>Season {season}</MenuItem>
                                         )}
                                     </Select>
                                    <NodeAnalytics
                                         analyticsData={[...permSalesHistoryDataFGNodeByMe, ...permSalesHistoryDataFiatNodeByMe]}
                                         classes={classes}
                                     />
                                 </div>
                             ),
                         },
                     ]}
                    />
                </div>
                <Footer/>
            </div>
        </div>
    );
}