import React, { useEffect } from "react";
import { useTheme } from "../../context/ThemeContext";
import "../../assets/css/darkMode.css";

const Parallax = () => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    document.body.className = isDarkMode ? "dark-theme" : "";
  }, [isDarkMode]);

  const header = document.querySelector('header');
  if (!header) return null;

  return (
    <div
      style={{
        height: `${header.offsetHeight-20}px`,
        width: '100%'
      }}
    />
  );
};

export default Parallax;
