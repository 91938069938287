import React, { useState, useEffect, useMemo } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export function NodeAnalytics({ analyticsData, classes }) {
    // All useState hooks together
    const [processedData, setProcessedData] = useState([]);

    // useEffect hooks
    useEffect(() => {
        if (Array.isArray(analyticsData)) {
            setProcessedData(analyticsData);
        }
    }, [analyticsData]);

    const summaryStats = useMemo(() => {
        if (!Array.isArray(processedData) || processedData.length === 0) {
            return {
                availableItems: 0,
                totalSold: 0,
                totalFiatSales: 0,
                totalFGSales: 0,
                topFiatSellers: [],
                topFGSellers: [],
                recentSales: [],
                averageSaleValue: 0,
                bestFiatDay: {
                    date: '',
                    total: 0,
                    items: []
                },
                bestFGDay: {
                    date: '',
                    total: 0,
                    items: []
                },
                analytics: {
                    fiat: {
                        averageSale: 0,
                        medianSale: 0,
                        averageDaily: 0
                    },
                    fg: {
                        averageSale: 0,
                        medianSale: 0,
                        averageDaily: 0
                    }
                },
                topItems: [],
                bestFiatWeek: {
                    weekStart: new Date(),
                    total: 0,
                    items: []
                },
                bestFGWeek: {
                    weekStart: new Date(),
                    total: 0,
                    items: []
                }
            };
        }

        // Group sales by seller
        const sellerMap = processedData.reduce((acc, item) => {
            const sellerName = item.reseller_name || 'Unknown';
            if (!acc[sellerName]) {
                acc[sellerName] = {
                    name: sellerName,
                    totalFiat: 0,
                    totalFG: 0,
                    itemsSold: 0
                };
            }

            acc[sellerName].totalFiat += item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            acc[sellerName].totalFG += item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            acc[sellerName].itemsSold += 1;

            return acc;
        }, {});

        // Group sales by supplier
        const supplierMap = processedData.reduce((acc, item) => {
            const supplierName = item.shareholder_name || 'Unknown';
            if (!acc[supplierName]) {
                acc[supplierName] = {
                    name: supplierName,
                    totalFiat: 0,
                    totalFG: 0,
                    itemsSold: 0
                };
            }

            acc[supplierName].totalFiat += item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            acc[supplierName].totalFG += item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            acc[supplierName].itemsSold += 1;

            return acc;
        }, {});

        // Calculate totals
        const totalFiatSales = processedData.reduce((acc, item) => {
            const fiatPrice = item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            return acc + fiatPrice;
        }, 0);

        const totalFGSales = processedData.reduce((acc, item) => {
            const fgPrice = item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            return acc + fgPrice;
        }, 0);

        // Calculate average sale value
        const allSaleValues = processedData.map(item => {
            const fgValue = item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            const fiatValue = item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            return fgValue + (fiatValue * 100); // Convert fiat to FG equivalent
        }).filter(value => value > 0);

        const averageSaleValue = allSaleValues.length
            ? allSaleValues.reduce((a, b) => a + b, 0) / allSaleValues.length
            : 0;

        // Group sales by day
        const salesByDay = processedData.reduce((acc, item) => {
            const date = new Date(item.sold_at).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = {
                    date,
                    totalFiat: 0,
                    totalFG: 0,
                    fiatItems: [],
                    fgItems: []
                };
            }

            const fgValue = item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            const fiatValue = item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;

            acc[date].totalFiat += fiatValue;
            acc[date].totalFG += fgValue;

            if (fiatValue > 0) acc[date].fiatItems.push(item);
            if (fgValue > 0) acc[date].fgItems.push(item);

            return acc;
        }, {});

        // Find best fiat and FG days
        const bestFiatDay = Object.values(salesByDay)
            .sort((a, b) => b.totalFiat - a.totalFiat)
            [0] || { date: '', totalFiat: 0, fiatItems: [] };

        const bestFGDay = Object.values(salesByDay)
            .sort((a, b) => b.totalFG - a.totalFG)
            [0] || { date: '', totalFG: 0, fgItems: [] };

        // Group items for best days
        const groupedFiatItems = bestFiatDay.fiatItems.reduce((acc, item) => {
            const key = `${item.name}-${item.ocr_string}-${item.fiat_price}`;
            if (!acc[key]) {
                acc[key] = {
                    name: item.name,
                    ocr_string: item.ocr_string,
                    fiatSales: {},
                    quantity: 0
                };
            }
            const price = item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            if (price > 0) {
                acc[key].fiatSales[price] = (acc[key].fiatSales[price] || 0) + 1;
            }
            acc[key].quantity += 1;
            return acc;
        }, {});

        const groupedFGItems = bestFGDay.fgItems.reduce((acc, item) => {
            const key = `${item.name}-${item.ocr_string}-${item.fg_price}`;
            if (!acc[key]) {
                acc[key] = {
                    name: item.name,
                    ocr_string: item.ocr_string,
                    fgSales: {},
                    quantity: 0
                };
            }
            const price = item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            if (price > 0) {
                acc[key].fgSales[price] = (acc[key].fgSales[price] || 0) + 1;
            }
            acc[key].quantity += 1;
            return acc;
        }, {});

        // Add weekly sales calculation
        const salesByWeek = processedData.reduce((acc, item) => {
            const date = new Date(item.sold_at);
            const weekStart = new Date(date);
            weekStart.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
            const weekKey = weekStart.toISOString().split('T')[0];

            if (!acc[weekKey]) {
                acc[weekKey] = {
                    weekStart,
                    totalFiat: 0,
                    totalFG: 0,
                    fiatItems: [],
                    fgItems: []
                };
            }

            const fiatValue = item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            const fgValue = item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;

            acc[weekKey].totalFiat += fiatValue;
            acc[weekKey].totalFG += fgValue;

            if (fiatValue > 0) acc[weekKey].fiatItems.push(item);
            if (fgValue > 0) acc[weekKey].fgItems.push(item);

            return acc;
        }, {});

        // Find best weeks
        const bestFiatWeek = Object.values(salesByWeek)
            .sort((a, b) => b.totalFiat - a.totalFiat)
            [0] || { weekStart: new Date(), totalFiat: 0, fiatItems: [] };

        const bestFGWeek = Object.values(salesByWeek)
            .sort((a, b) => b.totalFG - a.totalFG)
            [0] || { weekStart: new Date(), totalFG: 0, fgItems: [] };

        // Group items for best weeks
        const groupedFiatWeekItems = bestFiatWeek.fiatItems.reduce((acc, item) => {
            const key = `${item.name}-${item.ocr_string}-${item.fiat_price}`;
            if (!acc[key]) {
                acc[key] = {
                    name: item.name,
                    ocr_string: item.ocr_string,
                    fiatSales: {},
                    quantity: 0
                };
            }
            const price = item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            if (price > 0) {
                acc[key].fiatSales[price] = (acc[key].fiatSales[price] || 0) + 1;
            }
            acc[key].quantity += 1;
            return acc;
        }, {});

        const groupedFGWeekItems = bestFGWeek.fgItems.reduce((acc, item) => {
            const key = `${item.name}-${item.ocr_string}-${item.fg_price}`;
            if (!acc[key]) {
                acc[key] = {
                    name: item.name,
                    ocr_string: item.ocr_string,
                    fgSales: {},
                    quantity: 0
                };
            }
            const price = item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            if (price > 0) {
                acc[key].fgSales[price] = (acc[key].fgSales[price] || 0) + 1;
            }
            acc[key].quantity += 1;
            return acc;
        }, {});

        // Calculate fiat analytics
        const fiatSales = processedData
            .map(item => item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0)
            .filter(price => price > 0);

        const fiatMedian = fiatSales.length > 0
            ? fiatSales.sort((a, b) => a - b)[Math.floor(fiatSales.length / 2)]
            : 0;

        // Calculate FG analytics
        const fgSales = processedData
            .map(item => item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0)
            .filter(price => price > 0);

        const fgMedian = fgSales.length > 0
            ? fgSales.sort((a, b) => a - b)[Math.floor(fgSales.length / 2)]
            : 0;

        // Calculate daily averages
        const dailyTotals = processedData.reduce((acc, item) => {
            const date = new Date(item.sold_at).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = { fiat: 0, fg: 0 };
            }
            acc[date].fiat += item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            acc[date].fg += item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;
            return acc;
        }, {});

        const numDays = Object.keys(dailyTotals).length || 1;

        // Inside summaryStats useMemo, update the best items calculation
        const bestSellingItems = processedData.reduce((acc, item) => {
            const key = `${item.name}-${item.ocr_string}`;
            if (!acc[key]) {
                acc[key] = {
                    name: item.name,
                    ocr_string: item.ocr_string,
                    fiatSales: {}, // Store price counts: { price: quantity }
                    fgSales: {},
                    quantity: 0
                };
            }

            const fiatPrice = item.fiat_price === "???" ? 0 : parseFloat(item.fiat_price) || 0;
            const fgPrice = item.fg_price === "???" ? 0 : parseFloat(item.fg_price) || 0;

            if (fiatPrice > 0) {
                acc[key].fiatSales[fiatPrice] = (acc[key].fiatSales[fiatPrice] || 0) + 1;
            }
            if (fgPrice > 0) {
                acc[key].fgSales[fgPrice] = (acc[key].fgSales[fgPrice] || 0) + 1;
            }
            acc[key].quantity += 1;
            return acc;
        }, {});

        const topItems = Object.values(bestSellingItems)
            .map(item => ({
                ...item,
                totalFiat: Object.entries(item.fiatSales)
                    .reduce((sum, [price, qty]) => {
                        return sum + (parseFloat(price) * parseInt(qty));
                    }, 0),
                totalFG: Object.entries(item.fgSales)
                    .reduce((sum, [price, qty]) => {
                        return sum + (parseFloat(price) * parseInt(qty));
                    }, 0),
            }))
            .sort((a, b) => {
                const aTotal = a.totalFG + (a.totalFiat * 100);
                const bTotal = b.totalFG + (b.totalFiat * 100);
                return bTotal - aTotal;
            })
            .slice(0, 50);

        return {
            availableItems: 0,
            totalSold: processedData?.length || 0,
            totalFiatSales: totalFiatSales || 0,
            totalFGSales: totalFGSales || 0,
            topFiatSellers: Object.values(sellerMap || {})
                .sort((a, b) => b.totalFiat - a.totalFiat),
            topFGSellers: Object.values(sellerMap || {})
                .sort((a, b) => b.totalFG - a.totalFG)
                .filter((a) => a.totalFG > 0),
            topFGSuppliers: Object.values(supplierMap || {})
                .sort((a, b) => b.totalFG - a.totalFG)
                .filter((a) => a.totalFG > 0),
            recentSales: [...processedData]
                .sort((a, b) => new Date(b.sold_at) - new Date(a.sold_at))
                .map(item => ({
                    itemName: item.name,
                    soldBy: item.reseller_name,
                    soldAt: item.sold_at,
                    fiatPrice: parseFloat(item.fiat_price) || 0,
                    fgPrice: parseFloat(item.fg_price) || 0
                })),
            averageSaleValue,
            bestFiatDay: {
                date: bestFiatDay.date,
                total: bestFiatDay.totalFiat,
                items: Object.values(groupedFiatItems)
            },
            bestFGDay: {
                date: bestFGDay.date,
                total: bestFGDay.totalFG,
                items: Object.values(groupedFGItems)
            },
            analytics: {
                fiat: {
                    averageSale: fiatSales.length > 0
                        ? fiatSales.reduce((a, b) => a + b, 0) / fiatSales.length
                        : 0,
                    medianSale: fiatMedian,
                    averageDaily: Object.values(dailyTotals)
                        .reduce((sum, day) => sum + day.fiat, 0) / numDays
                },
                fg: {
                    averageSale: fgSales.length > 0
                        ? fgSales.reduce((a, b) => a + b, 0) / fgSales.length
                        : 0,
                    medianSale: fgMedian,
                    averageDaily: Object.values(dailyTotals)
                        .reduce((sum, day) => sum + day.fg, 0) / numDays
                }
            },
            topItems,
            bestFiatWeek: {
                weekStart: bestFiatWeek.weekStart,
                total: bestFiatWeek.totalFiat,
                items: Object.values(groupedFiatWeekItems)
            },
            bestFGWeek: {
                weekStart: bestFGWeek.weekStart,
                total: bestFGWeek.totalFG,
                items: Object.values(groupedFGWeekItems)
            }
        };
    }, [processedData]);

    // Add a helper function to format numbers
    const formatNumber = (num) => {
        if (!num) return '0';
        return Math.round(num * 100) / 100;
    };

    return (
        <div>
            {/* Summary Stats */}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div className={`${classes.bestDaySection} best-day-section`}>
                        <div className={`${classes.bestDayHeader} best-day-header`}>
                            <h4 className={`${classes.cardTitle} card-title`}>Total Items Sold</h4>
                        </div>
                        <div className={`${classes.bestDayItems} best-day-items`}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    {summaryStats.totalSold}
                                </div>
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>

            {/* Fiat Analytics */}
            <div className={`${classes.bestDaySection} best-day-section`}>
                <div className={`${classes.bestDayHeader} best-day-header`}>
                    <h4 className={`${classes.cardTitle} card-title`}>Fiat Sales Analytics</h4>
                </div>
                <div className={`${classes.bestDayItems} best-day-items`}>
                    <GridContainer>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Total Sales</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fiatValue} fiat-value`}>
                                        {formatNumber(summaryStats.totalFiatSales)} $
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Average Sale Value</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fiatValue} fiat-value`}>
                                        {formatNumber(summaryStats.analytics.fiat.averageSale)} $
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Median Sale Value</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fiatValue} fiat-value`}>
                                        {formatNumber(summaryStats.analytics.fiat.medianSale)} $
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Average Daily Sales</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fiatValue} fiat-value`}>
                                        {formatNumber(summaryStats.analytics.fiat.averageDaily)} $
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>

            {/* FG Analytics */}
            <div className={`${classes.bestDaySection} best-day-section`}>
                <div className={`${classes.bestDayHeader} best-day-header`}>
                    <h4 className={`${classes.cardTitle} card-title`}>FG Sales Analytics</h4>
                </div>
                <div className={`${classes.bestDayItems} best-day-items`}>
                    <GridContainer>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Total Sales</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fgValue} fg-value`}>
                                        {formatNumber(summaryStats.totalFGSales)} FG
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Average Sale Value</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fgValue} fg-value`}>
                                        {formatNumber(summaryStats.analytics.fg.averageSale)} FG
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Median Sale Value</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fgValue} fg-value`}>
                                        {formatNumber(summaryStats.analytics.fg.medianSale)} FG
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} md={3}>
                            <div className={`${classes.analyticsItem} analytics-item`}>
                                <div className={`${classes.analyticsLabel} analytics-label`}>Average Daily Sales</div>
                                <div className={`${classes.analyticsValue} analytics-value`}>
                                    <span className={`${classes.fgValue} fg-value`}>
                                        {formatNumber(summaryStats.analytics.fg.averageDaily)} FG
                                    </span>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>

            {/* Top Performers Section */}
            <GridContainer>
                <GridItem xs={12} md={6}>
                    <div className={`${classes.bestDaySection} best-day-section`}>
                        <div className={`${classes.bestDayHeader} best-day-header`}>
                            <h4 className={`${classes.cardTitle} card-title`}>Top Suppliers</h4>
                        </div>
                        <div className={`${classes.bestDayItems} best-day-items`}>
                            <div className={`${classes.scrollableSection} scrollable-list`}>
                                {summaryStats.topFGSuppliers?.map((supplier, index) => (
                                    <div key={index} className={`${classes.listItem} list-item`}>
                                        <span className="seller-name">
                                        {index === 0 && '🥇 '}
                                            {index === 1 && '🥈 '}
                                            {index === 2 && '🥉 '}
                                            {supplier.name}
                                        </span>
                                        <span className={`${classes.fgValue} fg-value`}>
                                            {formatNumber(supplier.totalFG)} FG
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <div className={`${classes.bestDaySection} best-day-section`}>
                        <div className={`${classes.bestDayHeader} best-day-header`}>
                            <h4 className={`${classes.cardTitle} card-title`}>Top Sellers</h4>
                        </div>
                        <div className={`${classes.bestDayItems} best-day-items`}>
                            <div className={`${classes.scrollableSection} scrollable-list`}>
                                {summaryStats.topFGSellers?.map((seller, index) => (
                                    <div key={index} className={`${classes.listItem} list-item`}>
                                        <span className="seller-name">
                                            {index === 0 && '🥇 '}
                                            {index === 1 && '🥈 '}
                                            {index === 2 && '🥉 '}
                                            {seller.name}
                                        </span>
                                        <span className={`${classes.fgValue} fg-value`}>
                                            {formatNumber(seller.totalFG)} FG
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </GridItem>

                <GridItem xs={12} md={12}>
                    <div className={`${classes.bestDaySection} best-day-section`}>
                        <div className={`${classes.bestDayHeader} best-day-header`}>
                            <h4 className={`${classes.cardTitle} card-title`}>Best Selling Items</h4>
                        </div>
                        <div className={`${classes.bestDayItems} best-day-items`}>
                            <div className={`${classes.scrollableSection} scrollable-list`}>
                                {summaryStats.topItems?.map((item, index) => (
                                    <div key={index} className={`${classes.listItem} list-item`}>
                                        <div className={`${classes.itemInfo} item-info`}>
                                            <span>{item.name}</span>
                                            {item.ocr_string && (
                                                <span className={`${classes.ocrString} ocr-string`}>{item.ocr_string}</span>
                                            )}
                                        </div>
                                        <div className={`${classes.priceContainer} price-container`}>
                                            {Object.keys(item.fiatSales).length > 0 && (
                                                <div className={`${classes.fiatValue} fiat-value`}>
                                                    {Object.entries(item.fiatSales)
                                                        .sort(([a], [b]) => parseFloat(a) - parseFloat(b))
                                                        .map(([price, qty], i) => (
                                                            <span key={i}>
                                                                {i > 0 && ', '}
                                                                {formatNumber(parseFloat(price))} $ × {qty}
                                                            </span>
                                                        ))}
                                                    <span> = </span>
                                                    <span className={`${classes.fiatValue} fiat-value`}>{formatNumber(item.totalFiat)} $</span>
                                                </div>
                                            )}
                                            {Object.keys(item.fgSales).length > 0 && (
                                                <div className={`${classes.fgValue} fg-value`}>
                                                    {Object.entries(item.fgSales)
                                                        .sort(([a], [b]) => parseFloat(a) - parseFloat(b))
                                                        .map(([price, qty], i) => (
                                                            <span key={i}>
                                                                {i > 0 && ', '}
                                                                {formatNumber(parseFloat(price))} FG × {qty}
                                                            </span>
                                                        ))}
                                                    <span> = </span>
                                                    <span className={`${classes.fgValue} fg-value`}>{formatNumber(item.totalFG)} FG</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>

            {/* Sales Analytics Section */}
            <div>
                <GridContainer>
                    <GridItem xs={12} md={6}>
                        <div className={`${classes.bestDaySection} best-day-section`}>
                            <div className={`${classes.bestDayHeader} best-day-header`}>
                                <div>
                                    <h4 className={`${classes.cardTitle} card-title`}>Best Fiat Sales Day</h4>
                                    <p>{summaryStats.bestFiatDay.date}</p>
                                </div>
                                <div>
                                    <div className={`${classes.analyticsLabel} analytics-label`}>Total Sales Volume</div>
                                    <div className={`${classes.analyticsValue} analytics-value`}>
                                        <span className={`${classes.fiatValue} fiat-value`}>
                                            {formatNumber(summaryStats.bestFiatDay.total)} $
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={`${classes.bestDayItems} best-day-items`}>
                                <h3 className={`${classes.analyticsLabel} analytics-label`}>Top Items Sold (Fiat)</h3>
                                <div className={`${classes.scrollableSection} scrollable-section scrollable-list`}>
                                    {summaryStats.bestFiatDay.items.map((item, index) => (
                                        <div key={index} className={`${classes.listItem} list-item`}>
                                            <div className={`${classes.itemInfo} item-info`}>
                                                <span>{item.name}</span>
                                                {item.ocr_string && (
                                                    <span className={`${classes.ocrString} ocr-string`}>{item.ocr_string}</span>
                                                )}
                                            </div>
                                            <div className={`${classes.priceContainer} price-container`}>
                                                <div className={`${classes.fiatValue} fiat-value`}>
                                                    {Object.entries(item.fiatSales)
                                                        .sort(([a], [b]) => parseFloat(a) - parseFloat(b))
                                                        .map(([price, qty], i) => (
                                                            <span key={i}>
                                                                {i > 0 && ', '}
                                                                {formatNumber(parseFloat(price))} $ × {qty}
                                                            </span>
                                                        ))}
                                                    <span> = </span>
                                                    <span className={`${classes.fiatValue} fiat-value`}>
                                                        {formatNumber(Object.entries(item.fiatSales)
                                                            .reduce((sum, [price, qty]) => sum + (parseFloat(price) * parseInt(qty)), 0))} $
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </GridItem>

                    <GridItem xs={12} md={6}>
                        <div className={`${classes.bestDaySection} best-day-section`}>
                            <div className={`${classes.bestDayHeader} best-day-header`}>
                                <div>
                                    <h4 className={`${classes.cardTitle} card-title`}>Best FG Sales Day</h4>
                                    <p>{summaryStats.bestFGDay.date}</p>
                                </div>
                                <div>
                                    <div className={`${classes.analyticsLabel} analytics-label`}>Total Sales Volume</div>
                                    <div className={`${classes.analyticsValue} analytics-value`}>
                                        <span className={`${classes.fgValue} fg-value`}>
                                            {formatNumber(summaryStats.bestFGDay.total)} FG
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={`${classes.bestDayItems} best-day-items`}>
                                <h3 className={`${classes.analyticsLabel} analytics-label`}>Top Items Sold (FG)</h3>
                                <div className={`${classes.scrollableSection} scrollable-section scrollable-list`}>
                                    {summaryStats.bestFGDay.items.map((item, index) => (
                                        <div key={index} className={`${classes.listItem} list-item`}>
                                            <div className={`${classes.itemInfo} item-info`}>
                                                <span>{item.name}</span>
                                                {item.ocr_string && (
                                                    <span className={`${classes.ocrString} ocr-string`}>{item.ocr_string}</span>
                                                )}
                                            </div>
                                            <div className={`${classes.priceContainer} price-container`}>
                                                <div className={`${classes.fgValue} fg-value`}>
                                                    {Object.entries(item.fgSales)
                                                        .sort(([a], [b]) => parseFloat(a) - parseFloat(b))
                                                        .map(([price, qty], i) => (
                                                            <span key={i}>
                                                                {i > 0 && ', '}
                                                                {formatNumber(parseFloat(price))} FG × {qty}
                                                            </span>
                                                        ))}
                                                    <span> = </span>
                                                    <span className={`${classes.fgValue} fg-value`}>
                                                        {formatNumber(Object.entries(item.fgSales)
                                                            .reduce((sum, [price, qty]) => sum + (parseFloat(price) * parseInt(qty)), 0))} FG
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>

            {/* Best Weeks Section */}
            <div>
                <GridContainer>
                    <GridItem xs={12} md={6}>
                        <div className={`${classes.bestDaySection} best-day-section`}>
                            <div className={`${classes.bestDayHeader} best-day-header`}>
                                <div>
                                    <h4 className={`${classes.cardTitle} card-title`}>Best Fiat Sales Week</h4>
                                    <p>{(() => {
                                        const weekEnd = new Date(summaryStats.bestFiatWeek.weekStart);
                                        weekEnd.setDate(summaryStats.bestFiatWeek.weekStart.getDate() + 6);
                                        return `${summaryStats.bestFiatWeek.weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`;
                                    })()}</p>
                                </div>
                                <div>
                                    <div className={`${classes.analyticsLabel} analytics-label`}>Total Sales Volume</div>
                                    <div className={`${classes.analyticsValue} analytics-value`}>
                                        <span className={`${classes.fiatValue} fiat-value`}>
                                            {formatNumber(summaryStats.bestFiatWeek.total)} $
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={`${classes.bestDayItems} best-day-items`}>
                                <h3 className={`${classes.analyticsLabel} analytics-label`}>Top Items Sold (Fiat Week)</h3>
                                <div className={`${classes.scrollableSection} scrollable-section scrollable-list`}>
                                    {summaryStats.bestFiatWeek.items.map((item, index) => (
                                        <div key={index} className={`${classes.listItem} list-item`}>
                                            <div className={`${classes.itemInfo} item-info`}>
                                                <span>{item.name}</span>
                                                {item.ocr_string && (
                                                    <span className={`${classes.ocrString} ocr-string`}>{item.ocr_string}</span>
                                                )}
                                            </div>
                                            <div className={`${classes.priceContainer} price-container`}>
                                                <div className={`${classes.fiatValue} fiat-value`}>
                                                    {Object.entries(item.fiatSales)
                                                        .sort(([a], [b]) => parseFloat(a) - parseFloat(b))
                                                        .map(([price, qty], i) => (
                                                            <span key={i}>
                                                                {i > 0 && ', '}
                                                                {formatNumber(parseFloat(price))} $ × {qty}
                                                            </span>
                                                        ))}
                                                    <span> = </span>
                                                    <span className={`${classes.fiatValue} fiat-value`}>
                                                        {formatNumber(Object.entries(item.fiatSales)
                                                            .reduce((sum, [price, qty]) => sum + (parseFloat(price) * parseInt(qty)), 0))} $
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </GridItem>

                    <GridItem xs={12} md={6}>
                        <div className={`${classes.bestDaySection} best-day-section`}>
                            <div className={`${classes.bestDayHeader} best-day-header`}>
                                <div>
                                    <h4 className={`${classes.cardTitle} card-title`}>Best FG Sales Week</h4>
                                    <p>{(() => {
                                        const weekEnd = new Date(summaryStats.bestFGWeek.weekStart);
                                        weekEnd.setDate(summaryStats.bestFGWeek.weekStart.getDate() + 6);
                                        return `${summaryStats.bestFGWeek.weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`;
                                    })()}</p>
                                </div>
                                <div>
                                    <div className={`${classes.analyticsLabel} analytics-label`}>Total Sales Volume</div>
                                    <div className={`${classes.analyticsValue} analytics-value`}>
                                        <span className={`${classes.fgValue} fg-value`}>
                                            {formatNumber(summaryStats.bestFGWeek.total)} FG
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={`${classes.bestDayItems} best-day-items`}>
                                <h3 className={`${classes.analyticsLabel} analytics-label`}>Top Items Sold (FG Week)</h3>
                                <div className={`${classes.scrollableSection} scrollable-section scrollable-list`}>
                                    {summaryStats.bestFGWeek.items.map((item, index) => (
                                        <div key={index} className={`${classes.listItem} list-item`}>
                                            <div className={`${classes.itemInfo} item-info`}>
                                                <span>{item.name}</span>
                                                {item.ocr_string && (
                                                    <span className={`${classes.ocrString} ocr-string`}>{item.ocr_string}</span>
                                                )}
                                            </div>
                                            <div className={`${classes.priceContainer} price-container`}>
                                                <div className={`${classes.fgValue} fg-value`}>
                                                    {Object.entries(item.fgSales)
                                                        .sort(([a], [b]) => parseFloat(a) - parseFloat(b))
                                                        .map(([price, qty], i) => (
                                                            <span key={i}>
                                                                {i > 0 && ', '}
                                                                {formatNumber(parseFloat(price))} FG × {qty}
                                                            </span>
                                                        ))}
                                                    <span> = </span>
                                                    <span className={`${classes.fgValue} fg-value`}>
                                                        {formatNumber(Object.entries(item.fgSales)
                                                            .reduce((sum, [price, qty]) => sum + (parseFloat(price) * parseInt(qty)), 0))} FG
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}