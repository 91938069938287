import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from './context/ThemeContext';
import { useTheme } from './context/ThemeContext';

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import DuePage from "./views/DuePage/DuePage.js";
import NodePage from "./views/NodePage/NodePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import SupplierItemPage from "./views/ItemPage/SupplierItemPage";
import UsersNodePage from "./views/UsersNodePage/UsersNodePage";
import InitiationPage from "./views/InitiationPage/InitiationPage";
import MehlbotSalesHistoryPage from "./views/MehlbotSalesHistoryPage/MehlbotSalesHistoryPage";
import PresetPage from "./views/MehlbotPickitPage/PresetPage";
import SupplierPage from "./views/SupplierPage/SupplierPage";
import SellerHelpPage from "./views/HelpPage/SellerHelpPage";
import PublicSearch from "./views/HelpPage/PublicSearch";
import NewItemPage from "./views/NewItemPage/NewItemPage";
import StaffPage from "./views/StaffPage/StaffPage";
import NewHelpPage from "./views/HelpPage/NewHelpPage";
import AnalyticsPage from "./views/AnalyticsPage/AnalyticsPage";
import {Toaster} from "react-hot-toast";
import RareItemPage from "views/ItemPage/RareItemPage";

var hist = createBrowserHistory();

function ThemedApp() {
    const { darkMode } = useTheme();
    
    const theme = React.useMemo(() => createMuiTheme({
        palette: {
            type: darkMode ? 'dark' : 'light',
            background: {
                default: darkMode ? '#121212' : '#fff',
                paper: darkMode ? '#1e1e1e' : '#fff',
            },
            text: {
                primary: darkMode ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                secondary: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    body: {
                        backgroundColor: darkMode ? '#121212' : '#fff',
                        color: darkMode ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                    },
                },
            },
        },
    }), [darkMode]);

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter history={hist}>
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                />
                <Switch>
                    <Route path="/todo" component={Components}/>
                    <Route path="/rs/initiate/:token" component={InitiationPage}/>
                    <Route path="/supplier" component={SupplierPage}/>
                    <Route path="/node" component={NodePage}/>
                    <Route path="/sales" component={MehlbotSalesHistoryPage}/>
                    <Route path="/users" component={UsersNodePage}/>
                    <Route path="/login" component={LoginPage}/>
                    <Route path="/due" component={DuePage}/>
                    <Route path="/staff" component={StaffPage}/>
                    <Route path="/help" component={NewHelpPage}/>
                    <Route path="/sitems" component={SupplierItemPage}/>
                    <Route path="/rares" component={RareItemPage}/>
                    <Route path="/help" component={SellerHelpPage}/>
                    <Route path="/d2rs" component={PublicSearch}/>
                    <Route path="/items" component={NewItemPage}/>
                    <Route path="/preset" component={PresetPage}/>
                    <Route path="/analytics" component={AnalyticsPage}/>
                    <Route path="/" component={PublicSearch}/>
                </Switch>
            </BrowserRouter>
        </MuiThemeProvider>
    );
}

ReactDOM.render(
    <ThemeProvider>
        <ThemedApp />
    </ThemeProvider>,
    document.getElementById("root")
);
